import React from 'react';

import Layout from '../components/Layout';

import Scroll from '../components/Scroll';

import appstore from '../assets/images/appstore.png';
import googleplay from '../assets/images/googleplay.png';
import config from '../../config';


const IndexPage = () => (
  <Layout>
    <section id="banner">
      <div className="inner">
        <h2>{config.heading}</h2>
        <p>{config.subHeading}</p>
        {/*<ul className="actions special">
          <li>
            <Scroll type="id" element="one">
              <a href="/#" className="button primary">
                Explore
              </a>
            </Scroll>
          </li>
        </ul>*/}
      </div>
      <Scroll type="id" element="one">
        <a href="#one" className="more">
          Узнать больше
        </a>
      </Scroll>
    </section>





    <section id="one" className="wrapper style4 special">
      <div className="inner">
        <header className="major">
          <h2>Пакет каналов русского телевидения Ruflix</h2>
          <p>
            Прекрасные новости! <br/> Совсем скоро на самых распространенных платформах <br/> появится бесплатный доступ к просмотру ваших любимых <br/> каналов русского телевидения.
          </p>
        </header>

        <ul className="features">
          <li>
            <span className="image fit"><img src={appstore} alt="" /></span>
            <p>
              Приложение для просмотра на устройствах Apple будет доступно в ближайшее время.
            </p>
          </li>
          <li>
            <span className="image fit"><img src={googleplay} alt="" /></span>
            <p>
              Приложение для просмотра на устройствах Android будет доступно в ближайшее время.
            </p>
          </li>

        </ul>
      </div>
    </section>

    {/*<section id="cta" className="wrapper style4">
      <div className="inner">
        <header>
          <h2>Arcue ut vel commodo</h2>
          <p>
            Aliquam ut ex ut augue consectetur interdum endrerit imperdiet amet
            eleifend fringilla.
          </p>
        </header>
        <ul className="actions stacked">
          <li>
            <a href="/#" className="button fit primary">
              Activate
            </a>
          </li>
          <li>
            <a href="/#" className="button fit">
              Learn More
            </a>
          </li>
        </ul>
      </div>
    </section>*/}
  </Layout>
);

export default IndexPage;
